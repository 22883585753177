import { Box, useTheme } from "@mui/material";
import Header from "../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "theme";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { enquiryApi } from "services/enquiry_api";
import DeleteBtn from "../components/DeleteBtn";
import { ClipLoader } from "react-spinners";
import { formatDate } from "utils";

const Enquiry = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setLoading] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);

  useEffect(() => {
    getEnquiries();
  }, []);

  const getEnquiries = async () => {
    setLoading(true);
    const res = await enquiryApi().getEnquiries();
    if (res.success) {
      setEnquiryData(res.data);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const deleteEnquiry = async (id) => {
    setLoading(true);
    const res = await enquiryApi().deleteEnquiry(id);
    if (res.success) {
      getEnquiries();
      toast.success("Enquiry deleted successfully");
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header title="Enquiry" subtitle="Frequently Asked Questions" />
      {isLoading && <ClipLoader size={25} />}

      {enquiryData &&
        enquiryData.map((enquiry) => {
          return (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={colors.greenAccent[500]} variant="h5">
                  {`${enquiry?.subject} from (${enquiry?.user})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{enquiry?.message}</Typography>
                <p>{`Date - ${formatDate(enquiry?.createdAt)}`}</p>
              </AccordionDetails>
              <Box display="flex" justifyContent="end" width={150}>
                <DeleteBtn deleteAction={() => deleteEnquiry(enquiry?.id)} />
              </Box>
            </Accordion>
          );
        })}

      <ToastContainer />
    </Box>
  );
};

export default Enquiry;
