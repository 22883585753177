import { baseUrl } from "./constants";

export const varietyApi = () => {
  return {
    getVarieties: () =>
      fetch(baseUrl + "/varieties", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteVariety: (id) =>
      fetch(baseUrl + "/variety/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    createVariety: (payload) =>
      fetch(baseUrl + "/variety", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    updateVariety: (payload, id) =>
      fetch(baseUrl + "/variety/" + id, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
