import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { tokens } from "theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatBox from "../components/StatBox";
import { usersApi } from "services/users_api";
import { fieldApi } from "services/field_api";
import { seedApi } from "services/seed_api";
import { newsApi } from "services/news_api";
import { formatDate } from "utils";
import { ClipLoader } from "react-spinners";

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalCount, setTotalCount] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [totalSeeds, setTotalSeeds] = useState(0);
  const [totalNews, setTotalNews] = useState(0);
  const [users, setUsers] = useState([])

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
    getFields();
    getSeeds();
    getNews();
    unverifiedUsers();
  }, []);

  const unverifiedUsers = async () => {
    const res = await usersApi().getUserNotVerified();
    if (res.success) {
      setUsers(res?.data);
    }
  };

  const getNews = async () => {
    const res = await newsApi().getNewsCount();
    if (res.success) {
      setTotalNews(res?.data);
    }
  };

  const getSeeds = async () => {
    const res = await seedApi().getSeedsCount();
    if (res.success) {
      setTotalSeeds(res?.data);
    }
  };

  const getUsers = async () => {
    const res = await usersApi().getUserCount();
    if (res.success) {
      setTotalCount(res?.data);
    }
  };

  const getFields = async () => {
    const res = await fieldApi().getFieldCount();
    if (res.success) {
      setTotalFields(res?.data);
    }
  };

  const activateUser = async (id) => {
    setLoading(true);
    const res = await usersApi().updateUser({status: 'verified'}, id);
    if (res.success) {
      unverifiedUsers();
      toast.success("User account activated");
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      {
        isLoading && <ClipLoader size={50} color="white" />
      }

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalCount}
            subtitle="Users"
            progress="0.75"
   
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalFields}
            subtitle="Fields"
            progress="0.50"
            icon={
              <PostAddOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalSeeds}
            subtitle="Seeds"
            progress="0.30"
            icon={
              <StorefrontOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalNews}
            subtitle="News"
            progress="0.80"
            icon={
              <NewspaperOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>

      {/* ROW 2 */}
      <Box mt="25px" pt="25px" backgroundColor={colors.primary[400]}>
        <Box>
          {/* <Box
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box> */}

          {/* <Box height="250px" m="-20px 0 0 0">
            <BarChart />
          </Box> */}
        </Box>

        {/* Transaction */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
               <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
               NEW USERS
              </Typography>
          </Box>
          {users.map((user) => (
            <Box
              key={`${user.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {user.first_name}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {user.last_name}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>Signed up at - {formatDate(user?.createdAt)}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                onClick={() => activateUser(user?.id) }
                sx={{
                  "&:hover": {
                    backgroundColor: colors.greenAccent[400],
                    color: "white",
                    cursor: "pointer",
                  },
                }}
              >
                ACTIVATE
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Home;
