import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { fieldApi } from "services/field_api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from '../components/Header';
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router-dom";

const CreateField = () => {
  const { state } = useLocation();
    const isNonMobile = useMediaQuery("(min-width:600px)");
      const [isLoading, setLoading] = useState(false);

    const handleFormSubmit = async (values) => {
      setLoading(true);
  
      let res =  state === null ? await fieldApi().uploadFields(values) : await fieldApi().uploadFields(values, state.id);
      if (res.success) {
        toast.success(`Field ${state === null ? "added" : "updated"} successfully`);
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    };
    

    const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  seed_project: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
    organization: yup.string().required("required"),
    location: yup.string().required("required"),
});
const initialValues = {
  name: state?.name || "",
  seed_project: state?.seed_project || "",
  email: state?.email || "",
  mobile:  state?.mobile || "",
  organization:  state?.organization || "",
  location:  state?.location || "",
  zone:  state?.zone || "",
  cac:  state?.cac || "",
  city:  state?.city || "",
  longitude: "", altitude: "", accuracy: "", production_year: "", seed_class: "", planting_date: "", variety: "", 
  prod_target: "",
  length: "", width: "", total_area: "", image: "", stem_quantity: "", stem_procured: "", stem_gen_number: "", certification_tag: "", seed_cost: "",
  transportation_cost: "", other_cost: "", specify_other_cost: "", currency: "NGN", seed_source: "", planting_area: "", area_unit: "",
  state:  state?.state || "", country:  state?.country || "1", latitude:  state?.latitude || "",
      soil_type: "", previous_crop: "", crops_around: "", comments: "", status: "", approved: ""
};


  return (
    <Box m="20px">
      <Header title="CREATE FIELD" subtitle="Create a New Seed Field" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Project"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.seed_project}
                name="seed_project"
                error={!!touched.seed_project && !!errors.seed_project}
                helperText={touched.seed_project && errors.seed_project}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="phone"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobile}
                name="mobile"
                error={!!touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Location"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
                name="location"
                error={!!touched.location && !!errors.location}
                helperText={touched.location && errors.location}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state}
                name="state"
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: "span 2" }}
              />
       

<TextField
                variant="filled"
                type="text"
                label="Production Year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.production_year}
                name="production_year"
                error={!!touched.production_year && !!errors.production_year}
                helperText={touched.production_year && errors.production_year}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Seed Class"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.seed_class}
                name="seed_class"
                error={!!touched.seed_class && !!errors.seed_class}
                helperText={touched.seed_class && errors.seed_class}
                sx={{ gridColumn: "span 2" }}
              />

<TextField
                fullWidth
                variant="filled"
                type="text"
                label="Organization"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.organization}
                name="organization"
                error={!!touched.organization && !!errors.organization}
                helperText={touched.organization && errors.organization}
                sx={{ gridColumn: "span 2" }}
              />
              
              
              <TextField
                variant="filled"
                type="text"
                label="Latitude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.latitude}
                name="latitude"
                error={!!touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Longitude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.longitude}
                name="longitude"
                error={!!touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Altitude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.altitude}
                name="altitude"
                error={!!touched.altitude && !!errors.altitude}
                helperText={touched.altitude && errors.altitude}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zone}
                name="zone"
                error={!!touched.zone && !!errors.zone}
                helperText={touched.zone && errors.zone}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Accuracy"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accuracy}
                name="accuracy"
                error={!!touched.accuracy && !!errors.accuracy}
                helperText={touched.accuracy && errors.accuracy}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="number"
                label="Length"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.length}
                name="length"
                error={!!touched.length && !!errors.length}
                helperText={touched.length && errors.length}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="number"
                label="Width"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.width}
                name="width"
                error={!!touched.width && !!errors.width}
                helperText={touched.width && errors.width}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="number"
                label="Total Area"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.total_area}
                name="total_area"
                error={!!touched.total_area && !!errors.total_area}
                helperText={touched.total_area && errors.total_area}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Stem Procured"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stem_procured}
                name="stem_procured"
                error={!!touched.stem_procured && !!errors.stem_procured}
                helperText={touched.stem_procured && errors.stem_procured}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="number"
                label="Stem Gem Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stem_gen_number}
                name="stem_gen_number"
                error={!!touched.stem_gen_number && !!errors.stem_gen_number}
                helperText={touched.stem_gen_number && errors.stem_gen_number}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Certification Tag"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.certification_tag}
                name="certification_tag"
                error={!!touched.certification_tag && !!errors.certification_tag}
                helperText={touched.certification_tag && errors.certification_tag}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="text"
                label="Certification Tag"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.certification_tag}
                name="certification_tag"
                error={!!touched.certification_tag && !!errors.certification_tag}
                helperText={touched.certification_tag && errors.certification_tag}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="text"
                label="Seed Cost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.seed_cost}
                name="seed_cost"
                error={!!touched.seed_cost && !!errors.seed_cost}
                helperText={touched.seed_cost && errors.seed_cost}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                variant="filled"
                type="number"
                label="Transportation Cost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.transportation_cost}
                name="transportation_cost"
                error={!!touched.transportation_cost && !!errors.transportation_cost}
                helperText={touched.transportation_cost && errors.transportation_cost}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                variant="filled"
                type="number"
                label="Other Cost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.other_cost}
                name="other_cost"
                error={!!touched.other_cost && !!errors.other_cost}
                helperText={touched.other_cost && errors.other_cost}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                variant="filled"
                type="text"
                label="Specific Other Cost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.specify_other_cost}
                name="specify_other_cost"
                error={!!touched.specify_other_cost && !!errors.specify_other_cost}
                helperText={touched.specify_other_cost && errors.specify_other_cost}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                variant="filled"
                type="text"
                label="Seed Source"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.seed_source}
                name="seed_source"
                error={!!touched.seed_source && !!errors.seed_source}
                helperText={touched.seed_source && errors.seed_source}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Planting Area"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.planting_area}
                name="planting_area"
                error={!!touched.planting_area && !!errors.planting_area}
                helperText={touched.planting_area && errors.planting_area}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                variant="filled"
                type="text"
                label="Area Unit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.area_unit}
                name="area_unit"
                error={!!touched.area_unit && !!errors.area_unit}
                helperText={touched.area_unit && errors.area_unit}
                sx={{ gridColumn: "span 2" }}
              />

              

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <SubmitBtn isLoading={isLoading} title={` ${state === null ? "CREATE" : "UPDATE"}`}/>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}



export default CreateField