import React, { useState } from "react";
import "./login.scss";
import Logo from "../../assets/images/logo.jpeg";
import { useNavigate } from 'react-router-dom';
import STButton from "pages/global/STButton";
import { useForm } from 'react-hook-form';
import { authApi } from "services/auth_api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    let navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(false);

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const submitData = async (val) => {
      setIsLoading(true);
      const res = await authApi().login(val);
      if(res.success){
        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem('userData', JSON.stringify(res.data));
        navigate('/dashboard/home');
      } else {
        toast.error(res.message)
      }
      setIsLoading(false);
    }

  return (
    <div className="hero">
      <div className="form-box">
        <div className="logo">
          <img src={Logo} alt="Seedtracker Logo" />
        </div>
        <form onSubmit={handleSubmit(submitData)}>
          <div className="input-group">
            <label htmlFor="email">Email address</label>
            <input type="email" {...register("email", { required: "Email is required" })} placeholder="Enter email address" />
            <p className="errorMessage">{errors.email?.message}</p>
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" {...register("password", { required: "Password is required" })} placeholder="Enter password" />
            <p className="errorMessage">{errors.password?.message}</p>
          </div>
          <STButton loading={isLoading}/>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
