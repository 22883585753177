import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";

const DeleteBtn = ({ deleteAction }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      width="100%"
      p="5px"
      display="flex"
      justifyContent="center"
      backgroundColor="red"
      borderRadius="4px"
      onClick={deleteAction}
      sx={{
        "&:hover": {
          backgroundColor: "#FF4315", // theme.palette.primary.main
          color: "white",
          cursor: "pointer",
        },
      }}
    >
      <DeleteOutlineOutlinedIcon />

      <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
        Delete
      </Typography>
    </Box>
  );
};

export default DeleteBtn;
