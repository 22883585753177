import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "theme";
import Header from "../components/Header";
import { fieldApi } from "services/field_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteBtn from "../components/DeleteBtn";
import ClipLoader from "react-spinners/ClipLoader";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import FieldsTemplate from "assets/templates/fields-template.csv";

const Fields = () => {
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [file, setFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const res = await fieldApi().uploadFields(formData);
    if (res.success) {
      getFields();
      toast.success(res.message);
      setFile(null);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const handleExport = () => {
    const file = new Blob([tableData], { type: "text/csv" });
    saveAs(file, "seedtracker-data.csv");
  };

  useEffect(() => {
    getFields();
  }, []);

  const getFields = async () => {
    const res = await fieldApi().getFields();
    setTableData(res.data);
  };

  const deleteField = async (id) => {
    setLoading(true);
    const res = await fieldApi().deleteField(id);
    if (res.success) {
      toast.success("Field deleted successfully");
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const deleteSelectedField = async () => {
    if (selectedRows.length >= 1) {
      setLoading(true);
      const res = await fieldApi().deleteSelectedField(selectedRows);
      if (res.success) {
        toast.success("Field deleted successfully");
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    } else {
      toast.error("No data selected");
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "Field ID" },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Phone number",
      cellClassName: "name-column--cell",
    },
    {
      field: "country",
      headerName: "Country",
      cellClassName: "name-column--cell",
    },
    {
      field: "seed_project",
      headerName: "Seed Project",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "production_year",
      headerName: "Production Year",
    },
    {
      field: "seed_class",
      headerName: "Seed Class",
      flex: 1,
    },
    {
      field: "variety",
      headerName: "Variety",
      flex: 1,
    },
    {
      field: "latitude",
      headerName: "Latitude",
    },
    {
      field: "longitude",
      headerName: "Longitude",
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[700]}
            borderRadius="4px"
            onClick={() => navigate('create',    
              {
                state : {
                    name: params.row.name,
                    mobile: params.row.mobile,
                    country: params.row.country,
                    seed_project: params.row.seed_project,
                    production_year: params.row.production_year,
                    seed_class: params.row.seed_class,
                    variety: params.row.variety,
                    latitude: params.row.latitude,
                    longitude: params.row.longitude,
                    id: params.row.id
                }
              }
            )}
          >
            <EditOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Edit
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => {
        return <DeleteBtn deleteAction={() => deleteField(params.row.id)} />;
      },
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="FIELDS"
        subtitle="Managing the seed field data"
        showBtn={true}
        page="fields/create"
      />
      <Box
        m="40px 0 0 0"
        height="175vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-start" m="10px 0">
          <Box>
            {isLoading && (
              <ClipLoader
                color={colors.greenAccent[700]}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                style={{ marginRight: "20px" }}
              />
            )}
          </Box>
          <Box mr="30px">
            <Box
              width="100%"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.redAccent[700]}
              borderRadius="4px"
              onClick={() => deleteSelectedField()}
              sx={{
                "&:hover": {
                  backgroundColor: colors.redAccent[400], // theme.palette.primary.main
                  color: "white",
                  cursor: "pointer",
                },
              }}
            >
              <DeleteOutlineOutlinedIcon />
              <Typography color="white" sx={{ ml: "5px" }}>
                DELETE SELECTED
              </Typography>
            </Box>
          </Box>

          <Box mr="30px">
            <input
              type="file"
              onChange={handleFileChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
            <Box
              width="100%"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[700]}
              borderRadius="4px"
              onClick={() => handleUploadClick()}
              sx={{
                "&:hover": {
                  backgroundColor: colors.greenAccent[400], // theme.palette.primary.main
                  color: "white",
                  cursor: "pointer",
                },
              }}
            >
              <FileDownloadOutlinedIcon />
              <Typography color="white" sx={{ ml: "5px" }}>
               IMPORT DATA
              </Typography>
            </Box>
          </Box>

          {file && (
            <Box mr="30px">
              <Box
                width="100%"
                p="5px"
                display="flex"
                justifyContent="center"
                backgroundColor={colors.greenAccent[700]}
                borderRadius="4px"
                onClick={() => uploadFile()}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.greenAccent[400], // theme.palette.primary.main
                    color: "white",
                    cursor: "pointer",
                  },
                }}
              >
                <CloudUploadOutlinedIcon />
                <Typography color="white" sx={{ ml: "5px" }}>
                  {`${"UPLOAD " + file.name}`}
                </Typography>
              </Box>
            </Box>
          )}

          <Box mr="30px">
            <CSVLink data={tableData} filename="seedtracker-data.csv">
              <Box
                width="100%"
                p="5px"
                display="flex"
                justifyContent="center"
                backgroundColor={colors.greenAccent[700]}
                borderRadius="4px"
                onClick={handleExport}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.greenAccent[400], // theme.palette.primary.main
                    color: "white",
                    cursor: "pointer",
                  },
                }}
              >
                <FileUploadOutlinedIcon />
                <Typography color="white" sx={{ ml: "5px" }}>
                  EXPORT DATA
                </Typography>
              </Box>
            </CSVLink>
          </Box>

          <Box mr="30px">
            <a href={FieldsTemplate} download="Fields-Template.csv">
              <Box
                width="100%"
                p="5px"
                display="flex"
                justifyContent="center"
                backgroundColor={colors.blueAccent[700]}
                borderRadius="4px"
                sx={{
                  "&:hover": {
                    backgroundColor: colors.blueAccent[400], // theme.palette.primary.main
                    color: "white",
                    cursor: "pointer",
                  },
                }}
              >
                <DownloadDoneOutlinedIcon />
                <Typography color="white" sx={{ ml: "5px" }}>
                  DOWNLOAD TEMPLATE
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>
        <DataGrid
          checkboxSelection
          rows={tableData}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          onSelectionModelChange={(ids) => {
            setSelectedRows(ids);
          }}
        />
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Fields;
