import { Box, Button, useTheme } from "@mui/material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { tokens } from "../../theme";
import ClipLoader from "react-spinners/ClipLoader";

const STButton = ({ loading }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" justifyContent="center" mt="20px">
      <Button
        fullWidth
        disabled = {loading}
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          ':hover': {
            backgroundColor: colors.blueAccent[500], // theme.palette.primary.main
            color: 'white',
          }      
        }}
        type="submit"
      >
        {loading ? (
            <ClipLoader
            color="#fff"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <>
            <LoginOutlinedIcon sx={{ mr: "10px" }} />
            LOGIN
          </>
        )}
      </Button>
    </Box>
  );
};

export default STButton;
