import { baseUrl } from "./constants";

export const usersApi = () => {
  return {
    getUsers: () =>
      fetch(baseUrl + "/users", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    getUserCount: () =>
      fetch(baseUrl + "/user/count", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    getUserNotVerified: () =>
      fetch(baseUrl + "/users/not-verified", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteUser: (id) =>
      fetch(baseUrl + "/user/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    createUser: (payload) =>
      fetch(baseUrl + "/user", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    updateUser: (payload, id) =>
      fetch(baseUrl + "/user/" + id, {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
