import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DeleteBtn from "../components/DeleteBtn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { tokens } from "theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { varietyApi } from "services/variety_api";

const Variety = () => {
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getVarieties();
  }, []);

  const getVarieties = async () => {
    setLoading(true);
    const res = await varietyApi().getVarieties();
    if (res.success) {
      setTableData(res.data);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const deleteVariety = async (id) => {
    setLoading(true);
    const res = await varietyApi().deleteVariety(id);
    if (res.success) {
      getVarieties();
      toast.success("Variety deleted successfully");
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const columns = [
    { field: "id", headerName: "Field ID" },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "clone_name",
      headerName: "Clone Name",
    },
    {
      field: "original_name",
      headerName: "Original Name",
    },
    {
      field: "year",
      headerName: "Year",
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[700]}
            borderRadius="4px"
            onClick={() => 
              navigate("create", {
                state: {
                  name: params.row.name,
                  clone_name: params.row.clone_name,
                  original_name:params.row.original_name,
                  year:params.row.year,
                  featured_traits:params.row.featured_traits,
                  traits:params.row.unit,
                  image: params.row.traits,
                  parameters: params.row.parameters,
                  images: params.row.images,
                },
              })
            }
            sx={{
              "&:hover": {
                backgroundColor: colors.greenAccent[400],
                color: "white",
                cursor: "pointer",
              },
            }}
          >
            <EditOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Edit
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => {
        return <DeleteBtn deleteAction={() => deleteVariety(params.row.id)} />;
      },
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="VARIETIES"
        subtitle="Managing the varieties data"
        showBtn={true}
        page="varieties/create"
      />
      <Box
        m="40px 0 0 0"
        height="105vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={isLoading}
        />
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Variety;
