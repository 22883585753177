import { baseUrl } from "./constants";

export const seedApi = () => {
  return {
    getSeeds: () =>
      fetch(baseUrl + "/seeds", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    getSeedsCount: () =>
      fetch(baseUrl + "/seeds/count", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteSeed: (id) =>
      fetch(baseUrl + "/seed/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    createSeed: (payload) =>
      fetch(baseUrl + "/seed", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    updateSeed: (payload, id) =>
      fetch(baseUrl + "/seed/" + id, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
