import { baseUrl } from "./constants";

export const cropApi = () => {
  return {
    getCrops: () =>
      fetch(baseUrl + "/crops", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteCrop: (id) =>
      fetch(baseUrl + "/crop/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    createCrop: (payload) =>
      fetch(baseUrl + "/crops", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    updateCrop: (payload, id) =>
      fetch(baseUrl + "/crop/" + id, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
