import React, { useEffect, useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Header from "../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DeleteBtn from "../components/DeleteBtn";
import { tokens } from "theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { newsApi } from "services/news_api";
import { useNavigate } from "react-router-dom";

const News = () => {
  let navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    setLoading(true);
    const res = await newsApi().getNews();
    if (res.success) {
      setTableData(res.data);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const deleteNews = async (id) => {
    setLoading(true);
    const res = await newsApi().deleteNews(id);
    if (res.success) {
      getNews();
      toast.success("Country deleted successfully");
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const columns = [
    { field: "id", headerName: "Field ID" },
    {
      field: "title",
      headerName: "Title",
    },
    {
      field: "message",
      headerName: "Message",
    },
    {
      field: "image",
      headerName: "Image",
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[700]}
            borderRadius="4px"
            onClick={() =>
              navigate("create", {
                state: {
                  title: params.row.title,
                  message: params.row.message,
                  image: params.row.image,
                  id: params.row.id,
                },
              })
            }
            sx={{
              "&:hover": {
                backgroundColor: colors.greenAccent[400],
                color: "white",
                cursor: "pointer",
              },
            }}
          >
            <EditOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Edit
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => {
        return <DeleteBtn deleteAction={() => deleteNews(params.row.id)} />;
      },
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="NEWS"
        subtitle="Managing the news data"
        showBtn={true}
        page="news/create"
      />
      <Box
        m="40px 0 0 0"
        height="105vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={isLoading}
        />
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default News;
