import { baseUrl } from "./constants";

export const enquiryApi = () => {
  return {
    getEnquiries: () =>
      fetch(baseUrl + "/enquiries", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteEnquiry: (id) =>
      fetch(baseUrl + "/enquiry/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
