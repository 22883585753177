import React, { useEffect, useMemo, useState } from "react";
import { Box, useTheme, Typography, IconButton } from "@mui/material";
import Header from "../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DeleteBtn from "../components/DeleteBtn";
import { tokens } from "theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { usersApi } from "services/users_api";
import { ClipLoader } from "react-spinners";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";


const Users = () => {

    const [tableData, setTableData] = useState([]);
    const [isLoading, setLoading] = useState(false);
  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        getUsers();
      }, []);
    
      const getUsers = async () => {
        setLoading(true);
        const res = await usersApi().getUsers();
        if (res.success) {
          setTableData(res.data);
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      };
    
      const deleteUser = async (id) => {
        setLoading(true);
        const res = await usersApi().deleteUser(id);
        if (res.success) {
            getUsers();
          toast.success("User deleted successfully");
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      };

      const activateUser = async (id) => {
        setLoading(true);
        const res = await usersApi().updateUser({status: 'verified'}, id);
        if (res.success) {
            getUsers();
          toast.success("User account activated");
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      };

      const filteredRows = useMemo(() => {
        return tableData.filter(row => {
          return (
            row.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.last_name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
      }, [searchQuery, tableData]);
    
      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
      };


      const columns = [
        { field: "id", headerName: "Field ID" },
        {
          field: "title",
          headerName: "Title",
        },
        {
          field: "status",
          headerName: "Status",
        },
        {
          field: "first_name",
          headerName: "First Name",
        },
        {
            field: "last_name",
            headerName: "Last Name",
          },
          {
            field: "userType",
            headerName: "User Type",
          },
          {
            field: "gender",
            headerName: "Gender",
          },
          {
            field: "username",
            headerName: "Username",
          },
          {
            field: "email",
            headerName: "Email",
            flex: 1
          },
          {
            field: "phone",
            headerName: "Phone",
          },
        {
          field: "action",
          headerName: "Action",
          renderCell: (params) => {
            return params.row.status !== 'verified' ? (
              <Box
                width="100%"
                p="5px"
                display="flex"
                justifyContent="center"
                backgroundColor={colors.greenAccent[700]}
                borderRadius="4px"
                onClick={() => activateUser(params.row.id) }
                sx={{
                  "&:hover": {
                    backgroundColor: colors.greenAccent[400],
                    color: "white",
                    cursor: "pointer",
                  },
                }}
              >
                <CheckCircleOutlineOutlinedIcon />
                <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                  Activate
                </Typography>
              </Box>
            ) : <Box></Box>;
          },
        },
        {
          field: "delete",
          headerName: "Delete",
          renderCell: (params) => {
            return <DeleteBtn deleteAction={() => deleteUser(params.row.id)} />;
          },
        },
      ];

  return (
    <Box m="20px">
             <Header
        title="USERS"
        subtitle="Managing the users data"
        showBtn={true}
        page="users/create"
      />
      {
        isLoading && <ClipLoader size={50} color="white" />
      }
         {/* SEARCH BAR */}
         <Box
        display="flex"
        width="400px"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" value={searchQuery} onChange={handleSearchInputChange} />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

        <Box
        m="40px 0 0 0"
        height="105vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={isLoading}
       
        />
      </Box>
      <ToastContainer />
    </Box>
  )
}

export default Users