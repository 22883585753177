import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "theme";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const Header = ({ title, subtitle, showBtn = false, page }) => {
  let navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box mb="30px">
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      </Box>
      {showBtn && (
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => navigate('/dashboard/' + page)}
          >
            <AddCircleOutlineOutlinedIcon sx={{ mr: "10px" }} />
            ADD FIELDS
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Header;
