import React from "react";
import { Box, Button } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";

const SubmitBtn = ({ isLoading, title }) => {
  return (
    <Box display="flex" justifyContent="start" mt="20px">
      <Button type="submit" color="secondary" variant="contained" style={{ width: "25%" }}>
        { isLoading ?  <ClipLoader size={20} /> : title}
      </Button>
    </Box>
  );
};

export default SubmitBtn;
