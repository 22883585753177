import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Image } from "cloudinary-react";
import Header from "../components/Header";
import { cropApi } from "services/crop_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router-dom";

const CreateCrop = () => {
  const { state } = useLocation();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [imageLink, setImageLink] = useState(state?.image || "");
  const [imageData, setImageData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    let imageUrl =  imageData === null ? imageLink  : await uploadImage(imageData);
    values.image = imageUrl;

    let res =  state === null ? await cropApi().createCrop(values) : await cropApi().updateCrop(values, state.id);
    if (res.success) {
      toast.success(`Crop ${state === null ? "added" : "updated"} successfully`);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", "seedtracker");

    const res = await fetch("https://api.cloudinary.com/v1_1/marvel-media/image/upload", {
      method: "POST",
      body: formData,
    });
    const res_1 = await res.json();
    setImageLink(res_1.secure_url);
    return res_1.secure_url;
  };

  const initialValues = {
    name: state?.name || "",
    country: state?.country || "",
    image: state?.image || "",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    country: yup.string().required("required"),
    image: yup.string().required("required"),
  });

  return (
    <Box m="20px">
      <Header title="CREATE CROP" subtitle="Create a New Crop" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Crop Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                name="country"
                error={!!touched.country && !!errors.country}
                helperText={touched.country && errors.country}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="file"
                // label="Image"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0].name);
                  setImageData(e.target.files[0]);
                }}
                name="image"
                error={!!touched.image && !!errors.image}
                helperText={touched.image && errors.image}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Image
              className="featured-image"
              cloudName="marvel-media"
              publicId={imageLink}
            />
           <SubmitBtn isLoading={isLoading} title={` ${state === null ? "CREATE" : "UPDATE"}`}/>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default CreateCrop;
