import { baseUrl } from "./constants";

export const fieldApi = () => {
  return {
    getFields: () =>
      fetch(baseUrl + "/fields", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    getFieldCount: () =>
      fetch(baseUrl + "/fields/count", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    uploadFields: (formData) =>
      fetch(baseUrl + "/fields/upload", {
        method: "POST",
        body: formData,
        headers: {
          // "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteField: (id) =>
      fetch(baseUrl + "/field/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((res) => res),

    deleteSelectedField: (ids) =>
      fetch(baseUrl + "/fields/delete", {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          ids,
        }),
      })
        .then((res) => res.json())
        .then((res) => res),
  };
};
