import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Image } from "cloudinary-react";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router-dom";
import { seedApi } from "services/seed_api";

const CreateSeed = () => {
  const { state } = useLocation();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [imageLink, setImageLink] = useState(state?.image || "");
  const [imageData, setImageData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    let imageUrl =
      imageData === null ? imageLink : await uploadImage(imageData);
    values.image = imageUrl;

    let res =
      state === null
        ? await seedApi().createSeed(values)
        : await seedApi().updateSeed(values, state.id);
    if (res.success) {
      toast.success(
        `Seed ${state === null ? "added" : "updated"} successfully`
      );
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", "seedtracker");

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/marvel-media/image/upload",
      {
        method: "POST",
        body: formData,
      }
    );
    const res_1 = await res.json();
    setImageLink(res_1.secure_url);
    return res_1.secure_url;
  };

  const initialValues = {
    country: state?.country || "",
    crop: state?.crop || "",
    variety: state?.variety || "",
    type: state?.type || "",
    quantity: state?.quantity || "",
    unit: state?.unit || "",
    available_at: state?.available_at || "",
    image: state?.image || "",
  };

  const checkoutSchema = yup.object().shape({
    crop: yup.string().required("required"),
    country: yup.string().required("required"),
    variety: yup.string().required("required"),
    type: yup.string().required("required"),
    quantity: yup.string().required("required"),
    unit: yup.string().required("required"),
    available_at: yup.string().required("required"),
    image: yup.string().required("required"),
  });
  
  return (
    <Box m="20px">
      <Header title="SELL SEED" subtitle="Sell Seeds" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Crop Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.crop}
                name="crop"
                error={!!touched.crop && !!errors.crop}
                helperText={touched.crop && errors.crop}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                name="country"
                error={!!touched.country && !!errors.country}
                helperText={touched.country && errors.country}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Variety"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.variety}
                name="variety"
                error={!!touched.variety && !!errors.variety}
                helperText={touched.variety && errors.variety}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Qauntity"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.quantity}
                name="quantity"
                error={!!touched.quantity && !!errors.quantity}
                helperText={touched.quantity && errors.quantity}
                sx={{ gridColumn: "span 2" }}
              />
                            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Unit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unit}
                name="unit"
                error={!!touched.unit && !!errors.unit}
                helperText={touched.unit && errors.unit}
                sx={{ gridColumn: "span 2" }}
              />
                            <TextField
                fullWidth
                variant="filled"
                type="file"
                // label="Image"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0].name);
                  setImageData(e.target.files[0]);
                }}
                name="image"
                error={!!touched.image && !!errors.image}
                helperText={touched.image && errors.image}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Available at"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.available_at}
                name="available_at"
                error={!!touched.available_at && !!errors.available_at}
                helperText={touched.available_at && errors.available_at}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Image
              className="featured-image"
              cloudName="marvel-media"
              publicId={imageLink}
            />
            <SubmitBtn
              isLoading={isLoading}
              title={` ${state === null ? "CREATE" : "UPDATE"}`}
            />
          </form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default CreateSeed;
