import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitBtn from "../components/SubmitBtn";
import { countryApi } from "services/country_api";

const CreateCountry = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    native_name: "",
    code: "",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    native_name: yup.string().required("required"),
    code: yup.string().required("required"),
  });

  const handleFormSubmit = async (values) => {
    setLoading(true);

    let res = await countryApi().createCountry(values);

    if (res.success) {
      toast.success(`Country added successfully`);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header title="CREATE Country" subtitle="Create countries" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Native Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.native_name}
                name="native_name"
                error={!!touched.native_name && !!errors.native_name}
                helperText={touched.native_name && errors.native_name}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <SubmitBtn isLoading={isLoading} title="CREATE" />
          </form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default CreateCountry;
