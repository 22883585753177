import React from "react";
import Topbar from "pages/global/Topbar";
import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import Sidebar from "pages/global/Sidebar";
import Fields from "./fields";
import CreateField from "./fields/create";
import Enquiry from "./enquiry";
import CreateCrop from "./crops/Create";
import Crops from "./crops";
import './index.scss';
import Seeds from "./seeds";
import CreateSeed from "./seeds/Create";
import Variety from "./varieties";
import CreateVariety from "./varieties/Create";
import Country from "./countries";
import CreateCountry from "./countries/Create";
import News from "./news";
import CreateNews from "./news/create";
import Users from "./users";
import CreateUser from "./users/Create";

const Dashboard = () => {
  return (
    <>
      <Sidebar/>
      <main className="content">
        <Topbar />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="fields" element={<Fields />} />
          <Route path="fields/create" element={<CreateField />} />
          <Route path="crops" element={<Crops />} />
          <Route path="crops/create" element={<CreateCrop />} />
          <Route path="seeds" element={<Seeds />} />
          <Route path="seeds/create" element={<CreateSeed />} />
          <Route path="varieties" element={<Variety />} />
          <Route path="varieties/create" element={<CreateVariety/>} />
          <Route path="countries" element={<Country />} />
          <Route path="countries/create" element={<CreateCountry/>} />
          <Route path="news" element={<News />} />
          <Route path="news/create" element={<CreateNews/>} />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="users" element={<Users />} />
          <Route path="users/create" element={<CreateUser/>} />
        </Routes>
      </main>
    </>
  );
};

export default Dashboard;
