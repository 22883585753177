import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Image } from "cloudinary-react";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router-dom";
import { varietyApi } from "services/variety_api";
import Dropzone from 'react-dropzone';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import './index.scss';
import { ClipLoader } from "react-spinners";

const CreateVariety = () => {
  const { state } = useLocation();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);

  const [images, setImages] = useState([]);
  const [imageLinks, setImageLinks] = useState([] || state.image);

  const initialValues = {
    name: state?.name || "",
    clone_name: state?.clone_name || "",
    original_name: state?.original_name || "",
    year: state?.year || "",
    featured_traits: state?.featured_traits || "",
    traits: state?.traits || "",
    parameters: state?.parameters || "",
    // images: state?.images || "",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    clone_name: yup.string().required("required"),
    original_name: yup.string().required("required"),
    year: yup.string().required("required"),
    featured_traits: yup.string().required("required"),
    traits: yup.string().required("required"),
    parameters: yup.string().required("required"),
  });

  const handleFormSubmit = async (values) => {
    setLoading(true);
    values.images = imageLinks

    let res =
      state === null
        ? await varietyApi().createVariety(values)
        : await varietyApi().updateVariety(values, state.id);
    if (res.success) {
      toast.success(
        `Varieties ${state === null ? "added" : "updated"} successfully`
      );
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const handleDrop = (files) => {
     const uploaders = files.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tags', `marvel-media, medium, gist`);
      formData.append("upload_preset", "seedtracker");
      formData.append("api_key", "615723581329213");
      formData.append("timestamp", (Date.now() / 1000) | 0);
      setLoadingImages(true);
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/marvel-media/image/upload",
        {
          method: "POST",
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          },
          body: formData,
        }
      );
      const res_1 = await res.json();
      const imageUrl = res_1.secure_url;
      setImageLinks(current => [...current, imageUrl])
    })

    Promise.all(uploaders).then(() => {
      setLoadingImages(false);
    })
  }

  return (
    <Box m="20px">
      <Header title="CREATE VARIETY" subtitle="Create Varieties" />
      {loadingImages && <ClipLoader size={30} color="white"/>}
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
             {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid
        }
        ) => (
          <form onSubmit={handleSubmit}>
        <h2 style={{ color: "white"}}>{errors.image}</h2>
               <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                      <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
                      <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Clone Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.clone_name}
                name="clone_name"
                error={!!touched.clone_name && !!errors.clone_name}
                helperText={touched.clone_name && errors.clone_name}
                sx={{ gridColumn: "span 2" }}
              />

<TextField
                fullWidth
                variant="filled"
                type="text"
                label="Year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.year}
                name="year"
                error={!!touched.year && !!errors.year}
                helperText={touched.year && errors.year}
                sx={{ gridColumn: "span 2" }}
              />
                      <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Original Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.original_name}
                name="original_name"
                error={!!touched.original_name && !!errors.original_name}
                helperText={touched.original_name && errors.original_name}
                sx={{ gridColumn: "span 2" }}
              />

<TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
  rows={6}
                label="Featured Traits"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.featured_traits}
                name="featured_traits"
                error={!!touched.featured_traits && !!errors.featured_traits}
                helperText={touched.featured_traits && errors.featured_traits}
                sx={{ gridColumn: "span 2" }}
              />
                      <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                rows={6}
                label="Traits"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.traits}
                name="traits"
                error={!!touched.traits && !!errors.traits}
                helperText={touched.traits && errors.traits}
                sx={{ gridColumn: "span 2" }}
              />

<TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                rows={6}
                label="Parameters"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parameters}
                name="parameters"
                error={!!touched.parameters && !!errors.parameters}
                helperText={touched.parameters && errors.parameters}
                sx={{ gridColumn: "span 2" }}
              />
              <Dropzone
              className=""
              multiple
              onDrop={handleDrop}
              onChange={(e) => {
                setImages(e.target.value)}}
              values={images}
              >
                {({getRootProps, getInputProps}) => (
                  <Box
                  sx={{
                    "&:hover": {
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  >
                    <div {...getRootProps({className: ""})}>
                      <span><FolderOpenOutlinedIcon/></span>
                      <Typography>Drag and drop your images here or Click to select the images</Typography>
                    </div>
                  </Box>
                )}
              </Dropzone>
      
            </Box>
            <Box
            display="flex"
            >
                {imageLinks !== null && imageLinks.map((el, i) => {
                  return (
                    <div key={i}>
                      <Image
                className="featured-image"
                cloudName="marvel-media"
                publicId={el}
              />
                    </div>
                  )
                })}
              </Box>
               <SubmitBtn
              isLoading={isLoading}
              title={` ${state === null ? "CREATE" : "UPDATE"}`}
            />
          </form>
         )}
        </Formik>
        <ToastContainer />
    </Box>
  )
}

export default CreateVariety