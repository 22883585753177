import React, { useEffect, useState } from "react";
import { Box, IconButton, useTheme, Popover, Typography } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../theme";
import { tokens } from "theme";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  let navigate = useNavigate();
  const [fullName, setFullName] = useState('');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('userData'));
    setFullName(user.first_name + ' ' + user.last_name);
  }, [])

  const logOut = () => {
    sessionStorage.clear();
    navigate('/');
  }

  return (
    <Box display="flex" justifyContent="flex-end" p={2}>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
  
        <IconButton onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>{fullName}</Typography>
        <Typography 
        onClick={() => logOut()}
        sx={{ p: 2,
           "&:hover": {
            backgroundColor: colors.redAccent[400], // theme.palette.primary.main
            color: "white",
            cursor: "pointer",
          },
        }}>LOG OUT</Typography>
      </Popover>
    </Box>
  );
};

export default Topbar;
