import { baseUrl } from "./constants";

export const authApi = () => {
    return {
      login: (payload) =>
        fetch(baseUrl + "/auth/admin-login", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(payload),
        }).then(res => res.json())
        .then(res => res)
    };
  };